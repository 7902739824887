import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Wish } from '../definitions/models/wish';
import { AuthService } from '../services/auth/auth.service';
import { apiUrl } from '../util/api-url';

@Injectable({
    providedIn: 'root',
})
export class WishesApiService {

    constructor(
        private readonly _http: HttpClient,
        private readonly _authService: AuthService,
    ) { }

    public allWishesByUser$(userId: string): Observable<Array<Wish>> {
        return this._http
            .get<Array<Partial<Wish>>>(
            `${ apiUrl }/listWishes/${ userId }`,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { headers: new HttpHeaders({ 'x-access-Token': this._authService.xAccessToken ?? '' }) },
        )
            .pipe(
                map(editions =>
                    editions.map(wish => Object.assign(new Wish(userId), wish)),
                ),
            );
    }

    public saveWish$(wish: Wish): Observable<boolean> {
        return this._http.post<boolean>(
            `${ apiUrl }/saveWish`,
            { id: wish.id, wish },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { headers: new HttpHeaders({ 'x-access-Token': this._authService.xAccessToken ?? '' }) },
        );
    }

    public takeWish$(wishId: string, takerId: string, taken: boolean): Observable<boolean> {
        return this._http.post<boolean>(
            `${ apiUrl }/takeWish`,
            { id: wishId, takerId, taken },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { headers: new HttpHeaders({ 'x-access-Token': this._authService.xAccessToken ?? '' }) },
        );
    }

    public deleteWish$(id: string): Observable<boolean> {
        return this._http.post<boolean>(
            `${ apiUrl }/deleteWish`,
            { id },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { headers: new HttpHeaders({ 'x-access-Token': this._authService.xAccessToken ?? '' }) },
        );
    }

}
