import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeAnimationTwoWay } from 'src/app/shared/definitions/animations/fade-animation';
import { User } from 'src/app/shared/definitions/models/user';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    animations: [fadeAnimationTwoWay],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent {

    @Input()
    public users?: Array<User> | null;

    @Input()
    public user?: User | null;

    @Output()
    public readonly closeMenu = new EventEmitter<undefined>();

    @Output()
    public readonly logout = new EventEmitter<undefined>();

    @Output()
    public readonly createUser = new EventEmitter<undefined>();

    @Output()
    public readonly configUser = new EventEmitter<User>();

    @Output()
    public readonly deleteUser = new EventEmitter<User>();

    @Output()
    public readonly openList = new EventEmitter<User>();

    public get isAdmin(): boolean {
        return this.user?.isAdmin ?? false;
    }

    public trackById(_index: number, user: User): string { return user.id; }

}
