import { trigger, transition, style, animate } from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
    transition(':enter',
        [style({ opacity: 0 }), animate('400ms ease-in-out', style({ opacity: 1 }))],
    ),
]);

export const fadeAnimationTwoWay = trigger('fadeAnimation', [
    transition(':enter',
        [style({ opacity: 0 }), animate('400ms ease-in-out', style({ opacity: 1 }))],
    ),
    transition(':leave',
        [style({ opacity: 1 }), animate('400ms ease-in-out', style({ opacity: 0 }))],
    ),
]);
