import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { NewUserDialogComponent } from './new-user-dialog.component';

@NgModule({
    declarations: [
        NewUserDialogComponent,
    ],
    exports: [
        NewUserDialogComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,

        MatButtonModule,
        MatDialogModule,
        MatInputModule,
    ],
    providers: [],
})
export class NewUserDialogModule { }
