import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
    createUserFn?: (userName: string) => void;
}

@Component({
    selector: 'app-new-user-dialog',
    templateUrl: './new-user-dialog.component.html',
    styleUrls: ['./new-user-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewUserDialogComponent{

    public newUserForm: FormGroup;

    constructor(
        private readonly _dialogRef: MatDialogRef<NewUserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly _data: DialogData,
    ) {
        this.newUserForm = new FormGroup(
            {
                username: new FormControl(
                    '',
                    [Validators.required],
                ),
            },
        );
    }

    public createUser(): void {
        const newUserName = this.newUserForm.value.username;

        if (newUserName && this._data.createUserFn) {
            this._data.createUserFn(newUserName);

            this._dialogRef.close();
        }
    }

}
