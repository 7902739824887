<h3>
    Anmeldung
</h3>

<mat-card
    appearance="raised"
    class="dialog"
>
    <ng-container *ngIf="users$ | async as users">

        <mat-form-field>
            <mat-select
                placeholder="Benutzer auswählen"
                [(value)]="selectedUser"
            >
                <mat-option
                    *ngFor="let user of users; trackBy: trackById;"
                    [value]="user"
                >
                    {{ user.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <ng-container *ngIf="!selectedUser || selectedUser.hasPassword; else NewPassword">
            <form [formGroup]="passwordForm" (ngSubmit)="login()">
                <mat-form-field>
                    <mat-label>Passwort</mat-label>
                    <input
                        formControlName="password"
                        matInput
                        maxlength="256"
                        title="Passwort"
                        type="password"
                    >
                    <mat-error *ngIf="passwordForm.controls['password'].touched && passwordForm.controls['password'].errors?.['required']">
                        Das Feld muss ausgefüllt sein
                    </mat-error>
                </mat-form-field>

                <button
                    title="Anmelden"
                    type="submit"
                    mat-raised-button
                    [disabled]="!selectedUser || passwordForm.invalid"
                >
                    <ng-container *ngIf="isLoggingIn$ | async; else LoginButton">
                        <mat-spinner diameter="24"></mat-spinner>
                    </ng-container>

                    <ng-template #LoginButton>
                        Anmelden
                    </ng-template>
                </button>
            </form>
        </ng-container>

        <ng-template #NewPassword>
            <form [formGroup]="newPasswordForm" (ngSubmit)="registerAndLogin()">
                <mat-form-field>
                    <mat-label>Neues Passwort</mat-label>
                    <input
                        formControlName="newPassword"
                        matInput
                        maxlength="256"
                        title="Passwort"
                        type="password"
                    >
                    <mat-error *ngIf="newPasswordForm.controls['newPassword'].touched && newPasswordForm.controls['newPassword'].errors?.['required']">
                        Das Feld muss ausgefüllt sein
                    </mat-error>
                    <mat-error *ngIf="newPasswordForm.controls['newPassword'].touched && newPasswordForm.controls['newPassword'].errors?.['minlength']">
                        Das Passwort braucht mindestens 8 Zeichen
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Passwort wiederholen</mat-label>
                    <input
                        formControlName="newPasswordRepeat"
                        matInput
                        [errorStateMatcher]="crossFieldErrorStateMatcher"
                        maxlength="256"
                        title="Passwort wiederholen"
                        type="password"
                    >
                    <mat-error *ngIf="newPasswordForm.controls['newPasswordRepeat'].errors?.['matchpassword'] || newPasswordForm.errors?.['matchpassword']">
                        Die Passwörter stimmen nicht überein
                    </mat-error>
                </mat-form-field>

                <button
                    title="Anmelden"
                    type="submit"
                    mat-raised-button
                    [disabled]="!selectedUser || newPasswordForm.invalid"
                >
                    <ng-container *ngIf="isLoggingIn$ | async; else LoginButton">
                        <mat-spinner diameter="24"></mat-spinner>
                    </ng-container>

                    <ng-template #LoginButton>
                        Anmelden
                    </ng-template>
                </button>
            </form>
        </ng-template>

    </ng-container>
</mat-card>
