<h2 mat-dialog-title>
    {{ data.title }}
</h2>

<div
    mat-dialog-content
>
    {{ data.message }}
</div>

<div
    mat-dialog-actions
    align="end"
>
    <button
        title="Abbrechen"
        type="button"
        mat-raised-button
        mat-dialog-close
    >
        Nein, abbrechen
    </button>

    <button
        title="Anmelden"
        type="button"
        mat-raised-button
        mat-dialog-close
        color="primary"
        (click)="confirm()"
    >
        {{ data.confirmText }}
    </button>
</div>
