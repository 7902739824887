import { Injectable } from '@angular/core';
import { catchError, delay, of, repeat, Subscription, switchMap, tap } from 'rxjs';
import { UpdatesApiService } from '../../api/updates-api.service';
import { UsersService } from '../users/users.service';
import { WishesService } from '../wishes/wishes.service';

const updateQueryDelay = 10000;

@Injectable({
    providedIn: 'root',
})
export class UpdatesService {

    private _lastUpdateTimeStamp?: number;
    private _subscription?: Subscription;

    constructor(
        private readonly _usersService: UsersService,
        private readonly _wishesService: WishesService,
        private readonly _updatesApi: UpdatesApiService,
    ) { }

    public startQueryingUpdates(): void {
        if (this._subscription) {
            return;
        }

        this._subscription = of(true)
            .pipe(
                delay(updateQueryDelay),
                repeat(),
                switchMap(() => this._updatesApi.updates$(this._lastUpdateTimeStamp ?? 0)),
                tap({
                    next: updateResponse => {
                        if (updateResponse) {
                            this._lastUpdateTimeStamp = updateResponse.timeStamp;

                            if (updateResponse.updates.some(update => update.users)) {
                                this._usersService.triggerReload();
                            }

                            const affectedUsers = updateResponse.updates
                                .map(update => update.wishes)
                                .filter((userId: string | undefined): userId is string => !!userId);

                            if (affectedUsers.length) {
                                this._wishesService.triggerReload(affectedUsers);
                            }
                        }
                    },
                    error: () => this._restartAfterError(),
                }),
                catchError(() => of(undefined)),
            )
            .subscribe();
    }

    private _restartAfterError(): void {
        this._subscription?.unsubscribe();
        this._subscription = undefined;

        this.startQueryingUpdates();
    }

}
