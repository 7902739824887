import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DisplayService {

    public static setPageHeight(): void {
        document.documentElement.style.height = `${ window.innerHeight }px`;
        document.documentElement.style.setProperty('--windowheight', `${ window.innerHeight }px`);
    }

}
