<h3>
    Admin-Benutzer anlegen
</h3>

<mat-card
    appearance="raised"
    class="dialog"
>
    Gib das Passwort ein, mit dem der Admin-Benutzer erstellt werden soll:

    <form [formGroup]="passwordForm" (ngSubmit)="createAdmin()">
        <mat-form-field>
            <mat-label>Neues Passwort</mat-label>
            <input
                formControlName="newPassword"
                matInput
                maxlength="256"
                title="Passwort"
                type="password"
            >
            <mat-hint align="end">
                {{passwordForm.controls['newPassword'].value.length}} / 256
            </mat-hint>
            <mat-error *ngIf="passwordForm.controls['newPassword'].touched && passwordForm.controls['newPassword'].errors?.['required']">
                Das Feld muss ausgefüllt sein
            </mat-error>
            <mat-error *ngIf="passwordForm.controls['newPassword'].touched && passwordForm.controls['newPassword'].errors?.['minlength']">
                Das Passwort braucht mindestens 8 Zeichen
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Passwort wiederholen</mat-label>
            <input
                formControlName="newPasswordRepeat"
                matInput
                [errorStateMatcher]="crossFieldErrorStateMatcher"
                maxlength="256"
                title="Passwort wiederholen"
                type="password"
            >
            <mat-error *ngIf="passwordForm.controls['newPasswordRepeat'].errors?.['matchpassword'] || passwordForm.errors?.['matchpassword']">
                Die Passwörter stimmen nicht überein
            </mat-error>
        </mat-form-field>

        <button
            title="Erstellen"
            type="submit"
            mat-raised-button
            [disabled]="passwordForm.invalid"
        >
            <ng-container *ngIf="isCreating$ | async; else LoginButton">
                <mat-spinner diameter="24"></mat-spinner>
            </ng-container>

            <ng-template #LoginButton>
                Erstellen
            </ng-template>
        </button>
    </form>
</mat-card>
