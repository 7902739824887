import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { Defaults } from 'src/app/shared/definitions/data/defaults';
import { User } from 'src/app/shared/definitions/models/user';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { UsersService } from 'src/app/shared/services/users/users.service';
import { logoutOnAuthError } from 'src/app/shared/util/logout-on-auth-error';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { NewUserDialogComponent } from '../../components/new-user-dialog/new-user-dialog.component';
import { UserConfigDialogComponent } from '../../components/user-config-dialog/user-config-dialog.component';

@Component({
    selector: 'app-wishlists',
    templateUrl: './wishlists.component.html',
    styleUrls: ['./wishlists.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WishlistsComponent {

    public readonly users$: Observable<Array<User>>;
    public readonly user$: Observable<User | null>;
    public menuOut = true;

    constructor(
        private readonly _router: Router,
        private readonly _authService: AuthService,
        private readonly _usersService: UsersService,
        private readonly _matSnackBar: MatSnackBar,
        private readonly _dialog: MatDialog,
    ) {
        this.users$ = this._usersService.users$()
            .pipe(
                tap({
                    error: () => this._matSnackBar.open(
                        'Die Datenbankverbindung ist fehlgeschlagen.',
                        '',
                        { duration: Defaults.SnackBarErrorDuration },
                    ),
                }),
                catchError(() => of([])),
                map(
                    users => users.filter(user =>
                        user.name !== 'admin' &&
                        user.id !== this._authService.user$.getValue()?.id,
                    ),
                ),
            );

        this.user$ = this._authService.user$.asObservable();
    }

    public openList(user: User): void {
        this.menuOut = false;
        this._router.navigateByUrl(`wishlist/${ user.id }`).then();
    }

    public logout(): void {
        this._authService.logout$().subscribe();
    }

    public openNewUserDialog(): void {
        this._dialog.open(NewUserDialogComponent, {
            enterAnimationDuration: Defaults.DialogOpenDuration,
            exitAnimationDuration: Defaults.DialogCloseDuration,
            data: {
                createUserFn: this._createUser.bind(this),
            },
        });
    }

    public openConfigUserDialog(selectedUser: User, users: Array<User>): void {
        const user = this._authService.user$.getValue();

        this._dialog.open(UserConfigDialogComponent, {
            enterAnimationDuration: Defaults.DialogOpenDuration,
            exitAnimationDuration: Defaults.DialogCloseDuration,
            data: {
                users,
                selectedUser,
                user,
                saveUserConfigFn: this._saveUserConfig.bind(this),
            },
        });
    }

    public openDeleteUserDialog(user: User): void {
        this._dialog.open(ConfirmationDialogComponent, {
            enterAnimationDuration: Defaults.DialogOpenDuration,
            exitAnimationDuration: Defaults.DialogCloseDuration,
            data: {
                title: 'Benutzer löschen',
                message: `Bist du sicher, dass du den Benutzer für ${ user.name } löschen willst?`,
                confirmText: 'Ja, Löschen',
                confirmFn: () => this._deleteUser(user),
            },
        });
    }

    private _createUser(userName: string): void {
        this._usersService.saveUser$(new User(userName))
            .pipe(
                tap({
                    next: () => this._matSnackBar.open(
                        `Der Benutzer für ${ userName } wurde erstellt`,
                        '',
                        { duration: Defaults.SnackBarInfoDuration },
                    ),
                    error: () => this._matSnackBar.open(
                        `Der Benutzer für ${ userName } konnte nicht erstellt werden`,
                        '',
                        { duration: Defaults.SnackBarErrorDuration },
                    ),
                }),
                logoutOnAuthError(this._matSnackBar, this._authService),
            )
            .subscribe();
    }

    private _saveUserConfig(user: User, password: string, passwordReset: boolean): void {
        this._usersService.saveUser$(user, password, passwordReset)
            .pipe(
                tap({
                    error: () => this._matSnackBar.open(
                        'Die Änderungen konnten nicht gespeichert werden',
                        '',
                        { duration: Defaults.SnackBarErrorDuration },
                    ),
                }),
                logoutOnAuthError(this._matSnackBar, this._authService),
            )
            .subscribe();
    }

    private _deleteUser(user: User): void {
        this._usersService.deleteUser$(user)
            .pipe(
                tap({
                    next: () => this._matSnackBar.open(
                        `Der Benutzer für ${ user.name } wurde gelöscht`,
                        '',
                        { duration: Defaults.SnackBarInfoDuration },
                    ),
                    error: () => this._matSnackBar.open(
                        `Der Benutzer für ${ user.name } konnte nicht gelöscht werden`,
                        '',
                        { duration: Defaults.SnackBarErrorDuration },
                    ),
                }),
                logoutOnAuthError(this._matSnackBar, this._authService),
            )
            .subscribe();
    }

}
