import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ConfirmationDialogModule } from '../../components/confirmation-dialog/confirmation-dialog.module';
import { EditWishDialogModule } from '../../components/edit-wish-dialog/edit-wish-dialog.module';
import { WishModule } from '../../components/wish/wish.module';

import { WishlistComponent } from './wishlist.component';

@NgModule({
    declarations: [
        WishlistComponent,
    ],
    imports: [
        BrowserModule,
        RouterModule,

        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatProgressSpinnerModule,

        ConfirmationDialogModule,
        EditWishDialogModule,
        WishModule,
    ],
    providers: [],
})
export class WishlistModule { }
