import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ConfirmationDialogModule } from '../../components/confirmation-dialog/confirmation-dialog.module';

import { NewUserDialogModule } from '../../components/new-user-dialog/new-user-dialog.module';
import { TitleBarModule } from '../../components/title-bar/title-bar.module';
import { UserConfigDialogModule } from '../../components/user-config-dialog/user-config-dialog.module';
import { UserMenuModule } from '../../components/user-menu/user-menu.module';

import { WishlistsComponent } from './wishlists.component';

@NgModule({
    declarations: [
        WishlistsComponent,
    ],
    imports: [
        BrowserModule,
        RouterModule,

        NewUserDialogModule,
        UserConfigDialogModule,
        ConfirmationDialogModule,
        UserMenuModule,
        TitleBarModule,
    ],
    providers: [],
})
export class WishlistsModule { }
