<div
    *ngIf="selectedUser$ | async as selectedUser"
    class="wishlist"
>
    <div class="wishlist__header">
        <h2>
            Wunschliste von {{ selectedUser.name }}
        </h2>

        <button
            *ngIf="canAddPresent$ | async"
            mat-fab
            color="primary"
            type="button"
            title="Geschenk ankündigen"
            (click)="openNewWishDialog(selectedUser.id, true)"
        >
            <mat-icon>redeem</mat-icon>
        </button>

        <button
            *ngIf="isEditor$ | async"
            mat-fab
            color="primary"
            type="button"
            title="Neuen Wunsch erstellen"
            (click)="openNewWishDialog(selectedUser.id)"
        >
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <div class="wishlist__content" *ngIf="users$ | async as users">
        <ng-template #LoadingTemplate>
            <div class="loading">
                <mat-spinner></mat-spinner>
            </div>
        </ng-template>

        <ng-container *ngIf="wishes$ | async as wishes; else LoadingTemplate">
            <ng-container
                *ngFor="let wish of wishes; trackBy: trackById"
            >
                <div
                    *ngIf="wish.giftingUserId ? (canSeeTakers$ | async) : true"
                    @fadeAnimation
                    class="wish"
                >
                    <ng-container *ngIf="currentUser$ | async as currentUser">
                        <mat-card appearance="raised">
                            <app-wish
                                [wish]="wish"
                                [user]="selectedUser"
                                [currentUser]="currentUser"
                                [users]="users"
                                (wishTaken)="takeWish(wish, $event, currentUser)"
                            ></app-wish>
                        </mat-card>
                        <ng-container *ngIf="currentUser.isAdmin || (wish.giftingUserId ? (wish.giftingUserId === currentUser.id) : (isEditor$ | async))">
                            <button
                                mat-mini-fab
                                color="primary"
                                type="button"
                                title="Wunsch bearbeiten"
                                (click)="openEditWishDialog(wish)"
                            >
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button
                                mat-mini-fab
                                color="primary"
                                type="button"
                                title="Wunsch löschen"
                                (click)="openDeleteWishDialog(wish)"
                            >
                                <mat-icon>delete</mat-icon>
                            </button>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </div>

</div>
