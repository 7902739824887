import { ChangeDetectionStrategy, Component, Inject, TrackByFunction } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CrossFieldErrorMatcher } from 'src/app/login/definitions/models/cross-field-error-matcher';
import { User } from 'src/app/shared/definitions/models/user';

const minPasswordLength = 8;

interface DialogData {
    users?: Array<User>;
    selectedUser?: User;
    user?: User;
    saveUserConfigFn?: (user: User, password: string, passwordReset: boolean) => void;
}

@Component({
    selector: 'app-user-config-dialog',
    templateUrl: './user-config-dialog.component.html',
    styleUrls: ['./user-config-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserConfigDialogComponent {

    public userConfigForm: FormGroup;

    public readonly crossFieldErrorStateMatcher = new CrossFieldErrorMatcher();

    public readonly users?: Array<User>;
    public readonly selectedUser?: User;
    private readonly _user?: User;

    constructor(
        private readonly _dialogRef: MatDialogRef<UserConfigDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly _data: DialogData,
    ) {
        this.users = this._data.users?.filter(user => !user.isAdmin && user.id !== this._data.selectedUser?.id);
        this.selectedUser = this._data.selectedUser;
        this._user = this._data.user;

        this.userConfigForm = new FormGroup(
            {
                managers: new FormArray<FormControl<string | null>>(
                    this.selectedUser?.managers.length
                        ? this.selectedUser.managers.map(managerId =>
                            new FormControl(managerId),
                        )
                        : [
                            new FormControl(''),
                        ],
                ),
                newPasswordGroup: new FormGroup(
                    {
                        newPassword: new FormControl(
                            '',
                            [Validators.minLength(minPasswordLength)],
                        ),
                        newPasswordRepeat: new FormControl(
                            '',
                        ),
                    },
                    {
                        validators: [this._validatePasswordRepeat.bind(this)],
                    },
                ),
                resetPassword: new FormControl<boolean>(
                    false,
                ),
            },
        );
    }

    public get managers(): FormArray<FormControl<string | null>> {
        return this.userConfigForm.get('managers') as FormArray;
    }

    public get newPasswordGroup(): FormGroup {
        return this.userConfigForm.get('newPasswordGroup') as FormGroup;
    }

    public get isAdmin(): boolean {
        return this._user?.isAdmin ?? false;
    }

    public addManager(): void {
        this.managers.insert(this.managers.controls.length, new FormControl(''));
        this.managers.markAsTouched();
        this.managers.markAsDirty();
    }

    public clearManager(index: number): void {
        this.managers.controls[index].patchValue('');
        this.managers.controls[index].markAsTouched();
        this.managers.controls[index].markAsDirty();
    }

    public removeManager(index: number): void {
        this.managers.removeAt(index);
        this.managers.markAsTouched();
        this.managers.markAsDirty();
    }

    public saveUser(): void {
        if (this.selectedUser && this._data.saveUserConfigFn) {
            this.selectedUser.managers = this.managers.value.filter((manager: string | null): manager is string => !!manager);

            this._data.saveUserConfigFn(
                this.selectedUser,
                this.userConfigForm.value.newPasswordGroup.newPassword,
                this.userConfigForm.value.resetPassword,
            );

            this._dialogRef.close();
        }
    }

    public trackById(_index: number, user: User): string { return user.id; }

    public trackByControl: TrackByFunction<FormControl> = (index: number, control: FormControl): FormControl => control;

    private _validatePasswordRepeat(control: AbstractControl): ValidationErrors | null {
        const newPassword = control.value.newPassword;
        const newPasswordRepeat = control.value.newPasswordRepeat;

        // Don't check the password if it's going to be reset.
        if (this.userConfigForm?.value.resetPassword) {
            return null;
        }

        if (newPassword && newPasswordRepeat !== newPassword) {
            return { matchpassword: true };
        }

        return null;
    }

}
