import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';

import { WishComponent } from './wish.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        WishComponent,
    ],
    exports: [
        WishComponent,
    ],
    imports: [
        CommonModule,

        MatCheckboxModule,
        MatExpansionModule,
        MatIconModule,
        MatTooltipModule,
    ],
    providers: [],
})
export class WishModule { }
