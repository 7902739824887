<h2 mat-dialog-title>
    Benutzereinstellungen bearbeiten
</h2>

<form
    class="dialog"
    [formGroup]="userConfigForm"
    mat-dialog-content
    (ngSubmit)="saveUser()"
>

    <div
        class="dialog__content"
    >

        <ng-container *ngIf="!selectedUser?.isAdmin">
            <h3>Verwalter einstellen</h3>

            <p>Verwalter können deine Wunschliste bearbeiten.</p>
            <ng-container formArrayName="managers">
                <div
                    *ngFor="let control of managers.controls; let index = index; let first = first; let last = last; trackBy: trackByControl;"
                    class="manager"
                >
                    <mat-form-field>
                        <mat-label>{{ first ? 'Verwalter' : 'Weiterer Verwalter' }}</mat-label>
                        <mat-select
                            [formControlName]="index"
                            placeholder="Verwalter auswählen"
                        >
                            <mat-option
                                *ngFor="let user of users; trackBy: trackById;"
                                [value]="user.id"
                            >
                                {{ user.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button
                        *ngIf="last"
                        type="button"
                        title="Verwalter hinzufügen"
                        mat-mini-fab
                        color="primary"
                        (click)="addManager()"
                    >
                        <mat-icon>add</mat-icon>
                    </button>
                    <button
                        type="button"
                        title="Verwalter entfernen"
                        mat-mini-fab
                        color="primary"
                        (click)="(first && last) ? clearManager(index) : removeManager(index)"
                    >
                        <mat-icon>remove</mat-icon>
                    </button>
                </div>
            </ng-container>
        </ng-container>

        <h3>Passwort ändern</h3>

        <ng-container *ngIf="!userConfigForm.value.resetPassword">
            <form formGroupName="newPasswordGroup">
                <mat-form-field>
                    <mat-label>Neues Passwort</mat-label>
                    <input
                        formControlName="newPassword"
                        matInput
                        maxlength="256"
                        title="Passwort"
                        type="password"
                    >
                    <mat-error *ngIf="newPasswordGroup.controls['newPassword'].touched && newPasswordGroup.controls['newPassword'].errors?.['minlength']">
                        Das Passwort braucht mindestens 8 Zeichen
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Passwort wiederholen</mat-label>
                    <input
                        formControlName="newPasswordRepeat"
                        matInput
                        [errorStateMatcher]="crossFieldErrorStateMatcher"
                        maxlength="256"
                        title="Passwort wiederholen"
                        type="password"
                    >
                    <mat-error *ngIf="newPasswordGroup.controls['newPasswordRepeat'].errors?.['matchpassword'] || newPasswordGroup.errors?.['matchpassword']">
                        Die Passwörter stimmen nicht überein
                    </mat-error>
                </mat-form-field>

            </form>
        </ng-container>

        <mat-checkbox
            *ngIf="isAdmin"
            formControlName="resetPassword"
            color="primary"
        >
            Passwort zurücksetzen
        </mat-checkbox>

    </div>

</form>

<div
    mat-dialog-actions
    align="end"
    >
    <button
        title="Abbrechen"
        type="button"
        mat-raised-button
        mat-dialog-close
    >
        Abbrechen
    </button>

    <button
        title="Speichern"
        type="button"
        mat-raised-button
        mat-dialog-close
        color="primary"
        [disabled]="userConfigForm.pristine || userConfigForm.invalid"
        (click)="saveUser()"
    >
        Speichern
    </button>
</div>
