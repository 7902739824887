import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FirstStepsComponent } from './login/views/first-steps/first-steps.component';
import { LoginComponent } from './login/views/login/login.component';
import { authGuard } from './shared/guards/auth-guard/auth.guard';
import { WishlistComponent } from './wishlist/views/wishlist/wishlist.component';
import { WishlistsComponent } from './wishlist/views/wishlists/wishlists.component';

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'setup',
        component: FirstStepsComponent,
    },
    {
        path: 'wishlist',
        component: WishlistsComponent,
        canActivate: [authGuard],
        children: [
            {
                path: ':userId',
                component: WishlistComponent,
            },
        ],
    },
    {
        path: '',
        redirectTo: 'wishlist',
        pathMatch: 'full',
    },
    {
        path: '**',
        redirectTo: 'wishlist',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
