import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { User } from 'src/app/shared/definitions/models/user';
import { Wish } from 'src/app/shared/definitions/models/wish';

@Component({
    selector: 'app-wish',
    templateUrl: './wish.component.html',
    styleUrls: ['./wish.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WishComponent implements OnChanges {

    @Input()
    public wish?: Wish;

    @Input()
    public user?: User;

    @Input()
    public users?: Array<User>;

    @Input()
    public currentUser?: User;

    @Output()
    public readonly wishTaken = new EventEmitter<boolean>();

    public canSeeTakers = false;
    public isTaker = false;
    public isGift = false;
    public giftingUser = '';
    public urls?: Array<SafeUrl>;
    public otherTakers: Array<string> = [];

    constructor(
        private readonly _sanitizer: DomSanitizer,
    ) {}

    public takeWish(change: MatCheckboxChange): void {
        this.wishTaken.emit(change.checked);
    }

    public trackByIndex(index: number): number { return index; }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.wish && this.currentUser && this.users) {
            this.canSeeTakers = this.wish.userId !== this.currentUser.id;
            this.isTaker = this.wish.takers.includes(this.currentUser.id);
            this.otherTakers = this.canSeeTakers ? this.wish.takers
                .filter(taker => taker !== this.currentUser?.id)
                .map(taker => this.users?.find(user => user.id === taker)?.name)
                .filter((taker: string | undefined): taker is string => !!taker) : [];
            this.isGift = this.wish.isGift;
            this.giftingUser = this.wish.isGift ? this.users?.find(user => user.id === this.wish?.giftingUserId)?.name ?? '' : '';
        }

        if (changes['wish']) {
            if (this.wish?.links) {
                this.urls = this.wish.links.map(link => {
                    if (/^http[s]?:\/\//.test(link)) {
                        return this._sanitizer.bypassSecurityTrustUrl(link);
                    } else {
                        return this._sanitizer.bypassSecurityTrustUrl(`http://${ link }`);
                    }
                });
            }
        }
    }

}
