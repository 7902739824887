import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, switchMap, tap } from 'rxjs';
import { UsersApiService } from '../../api/users-api.service';
import { User } from '../../definitions/models/user';

const oneMinuteInMs = 60000;

@Injectable({
    providedIn: 'root',
})
export class UsersService {

    private _cachedUsers: Array<User> = [];
    private _cacheTime?: number;
    private readonly _reload$ = new BehaviorSubject<undefined>(undefined);

    constructor(
        private readonly _usersApi: UsersApiService,
    ) { }

    public users$(): Observable<Array<User>> {
        const oneMinuteAgo = new Date().getTime() - oneMinuteInMs;

        return this._reload$
            .pipe(
                switchMap(() => {
                    if ((this._cacheTime ?? 0) > oneMinuteAgo) {
                        return of(this._cachedUsers);
                    } else {
                        return this._usersApi.allUsers$()
                            .pipe(
                                map(users => users.sort(this._sortByName)),
                                tap(users => {
                                    this._cachedUsers = users;
                                    this._cacheTime = new Date().getTime();
                                }),
                            );
                    }
                }),
            );
    }

    public saveUser$(user: User, password?: string, passwordReset?: boolean): Observable<boolean> {
        return this._usersApi.saveUser$(user, password, passwordReset)
            .pipe(
                tap({
                    next: () => this._reload(),
                    error: () => this._reload(),
                }),
            );
    }

    public deleteUser$(user: User): Observable<boolean> {
        return this._usersApi.deleteUser$(user.id)
            .pipe(
                tap({
                    next: () => this._reload(),
                    error: () => this._reload(),
                }),
            );
    }

    public triggerReload(): void {
        this._reload();
    }

    private _reload(): void {
        this._cacheTime = 0;
        this._reload$.next(undefined);
    }

    private _sortByName(prev: User, next: User): number {
        if (prev.name > next.name) {
            return 1;
        }

        if (prev.name < next.name) {
            return -1;
        }

        return 0;
    }

}
