import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, concat, distinctUntilChanged, filter, map, Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { fadeAnimation } from 'src/app/shared/definitions/animations/fade-animation';
import { Defaults } from 'src/app/shared/definitions/data/defaults';
import { User } from 'src/app/shared/definitions/models/user';
import { Wish } from 'src/app/shared/definitions/models/wish';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { UsersService } from 'src/app/shared/services/users/users.service';
import { WishesService } from 'src/app/shared/services/wishes/wishes.service';
import { logoutOnAuthError } from 'src/app/shared/util/logout-on-auth-error';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { EditWishDialogComponent } from '../../components/edit-wish-dialog/edit-wish-dialog.component';

@Component({
    selector: 'app-wishlist',
    templateUrl: './wishlist.component.html',
    styleUrls: ['./wishlist.component.scss'],
    animations: [fadeAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WishlistComponent implements OnDestroy {

    public readonly wishes$: Observable<Array<Wish> | undefined>;
    public readonly selectedUser$: Observable<User | undefined>;
    public readonly users$: Observable<Array<User>>;

    public readonly currentUser$: BehaviorSubject<User | null>;
    public readonly isEditor$ = new BehaviorSubject(false);
    public readonly canSeeTakers$ = new BehaviorSubject(false);
    public readonly canAddPresent$ = new BehaviorSubject(false);

    public readonly selectedUserId$ = new BehaviorSubject<string>('');

    private readonly _destroyed$ = new Subject<boolean>();

    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _router: Router,
        private readonly _usersService: UsersService,
        private readonly _authService: AuthService,
        private readonly _wishesService: WishesService,
        private readonly _matSnackBar: MatSnackBar,
        private readonly _dialog: MatDialog,
    ) {
        this.currentUser$ = this._authService.user$;

        this.users$ = this._usersService.users$();

        this._route.params
            .pipe(
                takeUntil(this._destroyed$),
            )
            .subscribe(params => {
                this.selectedUserId$.next(params['userId']);
            });

        this.wishes$ = this.selectedUserId$
            .pipe(
                distinctUntilChanged(),
                filter(userId => !!userId),
                switchMap(userId => concat(
                    //First remove the previous list's wishes, in case the next list takes longer to retrieve.
                    of(undefined),
                    this._wishesService.wishes$(userId)),
                ),
                map(wishes => wishes?.sort((a, b) =>
                    a.title !== b.title
                        ? a.title < b.title ? -1 : 1
                        : 0,
                )),
                map(wishes => wishes?.sort((a, b) =>
                    a.isGift
                        ? b.isGift ? 0 : 1
                        : b.isGift ? -1 : 0,
                )),
                logoutOnAuthError<Array<Wish> | undefined>(this._matSnackBar, this._authService),
            );

        this.selectedUser$ = this.selectedUserId$
            .pipe(
                filter(userId => !!userId),
                switchMap(userId =>
                    this._usersService.users$()
                        .pipe(
                            map(users => users.find(user => user.id === userId)),
                        ),
                ),
                tap(selectedUser => {
                    if (selectedUser) {
                        const user = this.currentUser$.getValue();

                        const isEditor = user?.isAdmin || (user?.id === selectedUser.id) || user?.isManagerOf(selectedUser) || false;
                        const canSeeTakers = selectedUser.id !== user?.id;

                        this.isEditor$.next(isEditor);
                        this.canSeeTakers$.next(canSeeTakers);
                        this.canAddPresent$.next(canSeeTakers && !user?.isAdmin);
                    } else {
                        this._router.navigateByUrl('wishlist');
                    }
                }),
            );
    }

    public openEditWishDialog(wish: Wish, createNew?: boolean): void {
        this._dialog.open(EditWishDialogComponent, {
            enterAnimationDuration: Defaults.DialogOpenDuration,
            exitAnimationDuration: Defaults.DialogCloseDuration,
            data: {
                createNew,
                wish,
                saveWishFn: (editedWish: Wish) => this._saveWish(editedWish, createNew),
            },
        });
    }

    public openNewWishDialog(userId: string, isGift?: boolean): void {
        const wish = new Wish(userId);
        const currentUser = this.currentUser$.getValue();

        if (isGift && currentUser) {
            wish.giftingUserId = currentUser.id;
        }

        this.openEditWishDialog(wish, true);
    }

    public openDeleteWishDialog(wish: Wish): void {
        this._dialog.open(ConfirmationDialogComponent, {
            enterAnimationDuration: Defaults.DialogOpenDuration,
            exitAnimationDuration: Defaults.DialogCloseDuration,
            data: {
                title: wish.isGift ? 'Geschenkankündigung löschen' : 'Wunsch löschen',
                message: `Bist du sicher, dass du ${ wish.isGift ? 'diese Ankündigung' : 'diesen Wunsch' } löschen willst?`,
                confirmText: 'Ja, löschen',
                confirmFn: () => this._deleteWish(wish),
            },
        });
    }

    public takeWish(wish: Wish, taken: boolean, user: User): void {
        this._takeWish(wish.id, user.id, taken);
    }

    public ngOnDestroy(): void {
        this._destroyed$.next(true);
        this._destroyed$.complete();
    }

    public trackById(_index: number, wish: Wish): string { return wish.id; }

    private _saveWish(wish: Wish, createNew?: boolean): void {
        this._wishesService.saveWish$(wish)
            .pipe(
                tap({
                    error: () => this._matSnackBar.open(
                        `Der Wunsch konnte nicht ${ createNew ? 'erstellt' : 'gespeichert' } werden`,
                        '',
                        { duration: Defaults.SnackBarErrorDuration },
                    ),
                }),
                logoutOnAuthError(this._matSnackBar, this._authService),
            )
            .subscribe();
    }

    private _takeWish(wishId: string, taker: string, taken: boolean): void {
        this._wishesService.takeWish$(wishId, taker, taken)
            .pipe(
                tap({
                    error: () => this._matSnackBar.open(
                        'Der Wunsch konnte nicht gespeichert werden',
                        '',
                        { duration: Defaults.SnackBarErrorDuration },
                    ),
                }),
                logoutOnAuthError(this._matSnackBar, this._authService),
            )
            .subscribe();
    }

    private _deleteWish(wish: Wish): void {
        this._wishesService.deleteWish$(wish)
            .pipe(
                tap({
                    error: () => this._matSnackBar.open(
                        'Der Wunsch konnte nicht gelöscht werden',
                        '',
                        { duration: Defaults.SnackBarErrorDuration },
                    ),
                }),
                logoutOnAuthError(this._matSnackBar, this._authService),
            )
            .subscribe();
    }

}
