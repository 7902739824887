import { Component, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { DisplayService } from './shared/services/display/display.service';
import { UpdatesService } from './shared/services/update/updates.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
    public title = 'Wunschlixt';

    constructor(
        private readonly _updatesService: UpdatesService,
    ) {
        DisplayService.setPageHeight();
        this._updatesService.startQueryingUpdates();
    }

    @HostListener('window:resize', ['$event'])
    public onResize(): void {
        DisplayService.setPageHeight();
    }
}
