import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, Observable, of, switchMap, tap } from 'rxjs';
import { Defaults } from 'src/app/shared/definitions/data/defaults';
import { User } from 'src/app/shared/definitions/models/user';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { UsersService } from 'src/app/shared/services/users/users.service';
import { CrossFieldErrorMatcher } from '../../definitions/models/cross-field-error-matcher';

const minPasswordLength = 8;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {

    public readonly users$: Observable<Array<User>>;
    public readonly passwordForm: FormGroup;
    public readonly newPasswordForm: FormGroup;
    public selectedUser?: User;

    public readonly isLoggingIn$ = new BehaviorSubject(false);

    public readonly crossFieldErrorStateMatcher = new CrossFieldErrorMatcher();

    constructor(
        private readonly _usersService: UsersService,
        private readonly _matSnackBar: MatSnackBar,
        private readonly _router: Router,
        private readonly _authService: AuthService,
    ) {
        if (this._authService.isLoggedIn$.getValue()) {
            this._router.navigateByUrl('/wishlist').then();
        }

        this.users$ = this._usersService.users$()
            .pipe(
                tap({
                    next: users => {
                        if (!users.length) {
                            this._router.navigateByUrl('/setup').then();
                        }
                    },
                    error: () => {
                        this._matSnackBar.open(
                            'Die Datenbankverbindung ist fehlgeschlagen.',
                            '',
                            { duration: Defaults.SnackBarErrorDuration },
                        );
                    },
                }),
                catchError(() => of([])),
            );

        this.passwordForm = new FormGroup(
            {
                password: new FormControl(
                    '',
                    [Validators.required],
                ),
            },
        );

        this.newPasswordForm = new FormGroup(
            {
                newPassword: new FormControl(
                    '',
                    [Validators.required, Validators.minLength(minPasswordLength)],
                ),
                newPasswordRepeat: new FormControl(
                    '',
                ),
            },
            {
                validators: [this._validatePasswordRepeat.bind(this)],
            },
        );
    }

    public login(): void {
        this.isLoggingIn$.next(true);

        const user = this.selectedUser;

        if (!user) {
            return;
        }

        this._authService.login$(user.id, this.passwordForm.controls['password'].value).
            pipe(
                tap({
                    next: () => {
                        this._router.navigateByUrl('/wishlist').then();
                    },
                    error: (response: HttpErrorResponse) => {
                        switch (response.status) {
                            case HttpStatusCode.Unauthorized:
                                this._matSnackBar.open(
                                    'Das Login ist fehlgeschlagen: Das Passwort ist inkorrekt',
                                    '',
                                    { duration: Defaults.SnackBarErrorDuration },
                                );
                                break;
                            default: this._matSnackBar.open(
                                'Das Login ist fehlgeschlagen',
                                '',
                                { duration: Defaults.SnackBarErrorDuration },
                            );
                        }

                    },
                }),
            )
            .subscribe({
                error: () => this.isLoggingIn$.next(false),
                complete: () => this.isLoggingIn$.next(false),
            });
    }

    public registerAndLogin(): void {
        this.isLoggingIn$.next(true);

        const user = this.selectedUser;

        if (!user) {
            return;
        }

        this._usersService.saveUser$(user, this.newPasswordForm.controls['newPassword'].value).
            pipe(
                tap({
                    next: () => this._matSnackBar.open(
                        'Dein Passwort wurde gesetzt. Du wirst jetzt angemeldet...',
                        '',
                        { duration: Defaults.SnackBarInfoDuration },
                    ),
                    error: () => this._matSnackBar.open(
                        'Der Admin-User konnte nicht erstellt werden; Es ist ein Fehler aufgetreten.',
                        '',
                        { duration: Defaults.SnackBarErrorDuration },
                    ),
                }),
                switchMap(() => this._authService.login$(user.id, this.newPasswordForm.controls['newPassword'].value)),
                tap({
                    next: () => {
                        this._router.navigateByUrl('/wishlist').then();
                    },
                    error: () => {
                        this._matSnackBar.open(
                            'Das Login ist fehlgeschlagen',
                            '',
                            { duration: Defaults.SnackBarErrorDuration },
                        );
                    },
                }),
            )
            .subscribe({
                error: () => this.isLoggingIn$.next(false),
                complete: () => this.isLoggingIn$.next(false),
            });
    }

    public trackById(_index: number, user: User): string { return user.id; }

    private _validatePasswordRepeat(control: AbstractControl): ValidationErrors | null {
        const newPassword = control.value.newPassword;
        const newPasswordRepeat = control.value.newPasswordRepeat;

        if (newPasswordRepeat !== newPassword) {
            return { matchpassword: true };
        }

        return null;
    }

}
