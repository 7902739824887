import { v4 as uuidv4 } from 'uuid';

export class User {
    public id = uuidv4();
    public name: string;
    public hasPassword = false;
    public managers: Array<string> = [];

    constructor(name: string) {
        this.name = name;
    }

    public get isAdmin(): boolean {
        return this.name === 'admin';
    }

    public isManagerOf(user: User): boolean {
        return user.managers.includes(this.id);
    }
}
