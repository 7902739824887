import { ChangeDetectionStrategy, Component, Inject, TrackByFunction } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Wish } from 'src/app/shared/definitions/models/wish';

interface DialogData {
    createNew?: boolean;
    wish?: Wish;
    saveWishFn?: (wish: Wish) => void;
}

@Component({
    selector: 'app-edit-wish-dialog',
    templateUrl: './edit-wish-dialog.component.html',
    styleUrls: ['./edit-wish-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditWishDialogComponent {

    public editWishForm: FormGroup;
    public createNew?: boolean;

    private readonly _wish?: Wish;

    constructor(
        private readonly _dialogRef: MatDialogRef<EditWishDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly _data: DialogData,
    ) {
        this._wish = this._data.wish;
        this.createNew = this._data.createNew;

        this.editWishForm = new FormGroup(
            {
                title: new FormControl(
                    this._wish?.title ?? '',
                    [Validators.required],
                ),
                links: new FormArray(
                    this._wish?.links.length
                        ? this._wish?.links.map(link =>
                            new FormControl(link),
                        )
                        : [
                            new FormControl(''),
                        ],
                ),
                details: new FormControl(
                    this._wish?.details ?? '',
                ),
                multipleTakers: new FormControl<boolean>(
                    this._wish?.allowMultipleTakers ?? false,
                ),
            },
        );
    }

    public get isGift(): boolean {
        return this._wish?.isGift || false;
    }

    public get links(): FormArray {
        return this.editWishForm.get('links') as FormArray;
    }

    public addLink(): void {
        this.links.insert(this.links.controls.length, new FormControl(''));
        this.links.markAsTouched();
        this.links.markAsDirty();
    }

    public clearLink(index: number): void {
        this.links.controls[index].patchValue('');
        this.links.controls[index].markAsTouched();
        this.links.controls[index].markAsDirty();
    }

    public removeLink(index: number): void {
        this.links.removeAt(index);
        this.links.markAsTouched();
        this.links.markAsDirty();
    }

    public saveWish(): void {
        if (this._wish && this._data.saveWishFn) {
            this._wish.title = this.editWishForm.value.title;
            this._wish.links = this.editWishForm.value.links.filter((link: string) => !!link);
            this._wish.details = this.editWishForm.value.details;
            this._wish.allowMultipleTakers = this.editWishForm.value.multipleTakers;

            this._data.saveWishFn(this._wish);

            this._dialogRef.close();
        }
    }

    public trackByControl: TrackByFunction<FormControl> = (index: number, control: FormControl): FormControl => control;

}
