<h2 mat-dialog-title>
    {{
        isGift
        ? createNew ? 'Geschenk ankündigen' : 'Geschenkankündigung bearbeiten'
        : createNew ? 'Neuen Wunsch anlegen' : 'Wunsch bearbeiten'
    }}
</h2>

<form
    class="dialog"
    [formGroup]="editWishForm"
    mat-dialog-content
    (ngSubmit)="saveWish()"
>

    <div
        class="dialog__content"
    >

        <p *ngIf="isGift">Der Besitzer der Wunschliste kann deine Ankündigung nicht sehen.</p>

        <mat-form-field>
            <mat-label>Titel</mat-label>
            <input
                formControlName="title"
                matInput
                maxlength="100"
                title="Titel"
                type="text"
            >
            <mat-error *ngIf="editWishForm.controls['title'].touched && editWishForm.controls['title'].errors?.['required']">
                Das Feld muss ausgefüllt sein
            </mat-error>
        </mat-form-field>

        <mat-checkbox
            *ngIf="!isGift"
            formControlName="multipleTakers"
            color="primary"
        >
            Dieser Wunsch kann von mehreren Personen markiert werden.
        </mat-checkbox>

        <ng-container formArrayName="links">
            <div
                *ngFor="let control of links.controls; let index = index; let first = first; let last = last; trackBy: trackByControl;"
                class="link"
            >
                <mat-form-field>
                    <mat-label>{{ first ? 'Link' : 'Weiterer Link'}}</mat-label>
                    <input
                        [formControlName]="index"
                        matInput
                        maxlength="500"
                        title="Link"
                        type="text"
                    >
                </mat-form-field>
                <button
                    *ngIf="last"
                    type="button"
                    title="Link hinzufügen"
                    mat-mini-fab
                    color="primary"
                    (click)="addLink()"
                >
                    <mat-icon>add</mat-icon>
                </button>
                <button
                    type="button"
                    title="Link entfernen"
                    mat-mini-fab
                    color="primary"
                    (click)="first && last ? clearLink(index) : removeLink(index)"
                >
                    <mat-icon>remove</mat-icon>
                </button>
            </div>
        </ng-container>


        <mat-form-field>
            <mat-label>Details</mat-label>
            <textarea
                formControlName="details"
                cdkTextareaAutosize
                cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="20"
                matInput
                title="Details"
            ></textarea>
        </mat-form-field>

    </div>

</form>

<div
    mat-dialog-actions
    align="end"
>
    <button
        title="Abbrechen"
        type="button"
        mat-raised-button
        mat-dialog-close
    >
        Abbrechen
    </button>

    <button
        [title]="createNew ? 'Anlegen' : 'Speichern'"
        type="button"
        mat-raised-button
        mat-dialog-close
        color="primary"
        [disabled]="editWishForm.invalid"
        (click)="saveWish()"
    >
        {{ createNew ? 'Anlegen' : 'Speichern' }}
    </button>
</div>
