import { v4 as uuidv4 } from 'uuid';

export class Wish {
    public id = uuidv4();
    public userId: string;
    public sortIndex = 0;
    public title = '';
    public links: Array<string> = [];
    public details = '';
    public takers: Array<string> = [];
    public allowMultipleTakers = false;

    // If the wish has a giftingUserId, it is a gift and can not be seen by the wishlist owner nor taken by any user.
    public giftingUserId = '';

    constructor(userId: string, isPresentId = '') {
        this.userId = userId;
        this.giftingUserId = isPresentId;
    }

    public get isTaken(): boolean {
        return !this.allowMultipleTakers && !!this.takers.length;
    }

    public get isGift(): boolean {
        return !!this.giftingUserId;
    }
}
