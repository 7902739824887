import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UpdateResponse } from '../definitions/interfaces/update-response';
import { AuthService } from '../services/auth/auth.service';
import { apiUrl } from '../util/api-url';

@Injectable({
    providedIn: 'root',
})
export class UpdatesApiService {

    constructor(
        private readonly _http: HttpClient,
        private readonly _authService: AuthService,
    ) { }

    public updates$(timeStamp: number): Observable<UpdateResponse> {
        return this._http
            .get<UpdateResponse>(
            `${ apiUrl }/listUpdates/${ timeStamp }`,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { headers: new HttpHeaders({ 'x-access-Token': this._authService.xAccessToken ?? '' }) },
        );
    }

}
