import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';

import { UserMenuComponent } from './user-menu.component';

@NgModule({
    declarations: [
        UserMenuComponent,
    ],
    exports: [
        UserMenuComponent,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatListModule,
    ],
    providers: [],
})
export class UserMenuModule { }
