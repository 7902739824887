import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

import { TitleBarComponent } from './title-bar.component';

@NgModule({
    declarations: [
        TitleBarComponent,
    ],
    exports: [
        TitleBarComponent,
    ],
    imports: [
        CommonModule,

        MatButtonModule,
        MatCardModule,
        MatIconModule,
    ],
    providers: [],
})
export class TitleBarModule { }
