import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Md5 } from 'ts-md5';
import { User } from '../definitions/models/user';
import { AuthService } from '../services/auth/auth.service';
import { apiUrl } from '../util/api-url';

@Injectable({
    providedIn: 'root',
})
export class UsersApiService {

    constructor(
        private readonly _http: HttpClient,
        private readonly _authService: AuthService,
    ) { }

    public allUsers$(): Observable<Array<User>> {
        return this._http
            .get<Array<Partial<User>>>(
            `${ apiUrl }/listUsers`,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { headers: new HttpHeaders({ 'x-access-Token': this._authService.xAccessToken ?? '' }) },
        )
            .pipe(
                map(users =>
                    users.map(user => Object.assign(new User(user.name ?? ''), user)),
                ),
            );
    }

    public saveUser$(user: User, password?: string, passwordReset?: boolean): Observable<boolean> {
        const passwordObj = passwordReset
            ? { password: '' }
            : password
                ? { password: Md5.hashStr(password) }
                : {};

        return this._http.post<boolean>(
            `${ apiUrl }/saveUser`,
            { id: user.id, managers: user.managers, name: user.name, ...passwordObj },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { headers: new HttpHeaders({ 'x-access-Token': this._authService.xAccessToken ?? '' }) },
        );
    }

    public deleteUser$(id: string): Observable<boolean> {
        return this._http.post<boolean>(
            `${ apiUrl }/deleteUser`,
            { id },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { headers: new HttpHeaders({ 'x-access-Token': this._authService.xAccessToken ?? '' }) },
        );
    }

}
