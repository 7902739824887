import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, switchMap, of, map, tap } from 'rxjs';
import { WishesApiService } from '../../api/wishes-api.service';
import { Wish } from '../../definitions/models/wish';

const oneMinuteInMs = 60000;

@Injectable({
    providedIn: 'root',
})
export class WishesService {

    private _cachedWishes: Array<Wish> = [];
    private _cacheTime?: number;
    private _cacheUserId?: string;
    private readonly _reload$ = new BehaviorSubject<undefined>(undefined);

    constructor(
        private readonly _wishesApi: WishesApiService,
    ) { }

    public wishes$(userId: string): Observable<Array<Wish>> {
        const oneMinuteAgo = new Date().getTime() - oneMinuteInMs;

        return this._reload$
            .pipe(
                switchMap(() => {
                    if ((this._cacheUserId === userId) && (this._cacheTime ?? 0) > oneMinuteAgo) {
                        return of(this._cachedWishes);
                    } else {
                        return this._wishesApi.allWishesByUser$(userId)
                            .pipe(
                                map(wishes => wishes.sort((a, b) => a.sortIndex - b.sortIndex)),
                                tap(wishes => {
                                    this._cachedWishes = wishes;
                                    this._cacheTime = new Date().getTime();
                                    this._cacheUserId = userId;
                                }),
                            );
                    }
                }),
            );
    }

    public saveWish$(wish: Wish): Observable<boolean> {
        return this._wishesApi.saveWish$(wish)
            .pipe(
                tap({
                    next: () => this._reload(),
                    error: () => this._reload(),
                }),
            );
    }

    public takeWish$(wishId: string, taker: string, taken: boolean): Observable<boolean> {
        return this._wishesApi.takeWish$(wishId, taker, taken)
            .pipe(
                tap({
                    next: () => this._reload(),
                    error: () => this._reload(),
                }),
            );
    }

    public deleteWish$(wish: Wish): Observable<boolean> {
        return this._wishesApi.deleteWish$(wish.id)
            .pipe(
                tap({
                    next: () => this._reload(),
                    error: () => this._reload(),
                }),
            );
    }

    public triggerReload(affectedUsers: Array<string>): void {
        if (this._cacheUserId && affectedUsers.includes(this._cacheUserId)) {
            this._reload();
        }
    }

    private _reload(): void {
        this._cacheTime = 0;
        this._reload$.next(undefined);
    }

}
