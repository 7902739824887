import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
    title?: string;
    message?: string;
    confirmText?: string;
    confirmFn?: () => void;
}

@Component({
    selector: 'confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent{

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
    ) {
    }

    public confirm(): void {
        if (this.data.confirmFn) {
            this.data.confirmFn();
        }
    }

}
