import { HttpStatusCode } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, map, Observable, OperatorFunction } from 'rxjs';
import { Defaults } from '../definitions/data/defaults';
import { AuthService } from '../services/auth/auth.service';

export const logoutOnAuthError = <T>(snackBar: MatSnackBar, authService: AuthService): OperatorFunction<T, T | undefined> =>
    (source: Observable<T>) => source.pipe(
        catchError(response => {
            if (response.status === HttpStatusCode.Unauthorized) {
                snackBar.open(
                    'Deine Sitzung wurde beendet',
                    '',
                    { duration: Defaults.SnackBarErrorDuration },
                );

                return authService.logout$().pipe(map(() => undefined));
            } else {
                throw response;
            }
        }),
    );
