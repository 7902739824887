import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';

@NgModule({
    declarations: [
        ConfirmationDialogComponent,
    ],
    exports: [
        ConfirmationDialogComponent,
    ],
    imports: [
        CommonModule,

        MatButtonModule,
        MatDialogModule,
    ],
    providers: [],
})
export class ConfirmationDialogModule { }
