import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-title-bar',
    templateUrl: './title-bar.component.html',
    styleUrls: ['./title-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleBarComponent {

    @Output()
    public readonly menuOutChange = new EventEmitter();

    private _menuOut = false;

    public get menuOut(): boolean {
        return this._menuOut;
    }

    @Input()
    public set menuOut(value: boolean) {
        this._menuOut = value;
        this.menuOutChange.emit(value);
    }

}
