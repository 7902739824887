import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';
import { AuthApiService } from '../../api/auth-api.service';
import { User } from '../../definitions/models/user';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    public xAccessToken: string | null;
    public user$ = new BehaviorSubject<User | null>(null);
    public isLoggedIn$ = new BehaviorSubject(false);

    constructor(
        private readonly _authApi: AuthApiService,
        private readonly _router: Router,
    ) {
        this.xAccessToken = localStorage.getItem('bukiro-wishlist-token');

        if (this.xAccessToken) {
            // If a token is saved, set logged in right away for the auth guard.
            // If the token can't be verified in the next step, the page will navigate to login automatically.
            this.isLoggedIn$.next(true);

            this.setCurrentUser$().subscribe({
                error: () => {
                    this._router.navigateByUrl('/login').then();
                },
            });
        }
    }

    public login$(userId: string, password: string): Observable<User> {
        return this._authApi.login$(userId, password)
            .pipe(
                tap(result => {
                    this.xAccessToken = result.token;
                }),
                switchMap(() => this.setCurrentUser$()),
            );
    }

    public logout$(): Observable<HttpResponse<null>> {
        return this._authApi.logout$(this.xAccessToken ?? '')
            .pipe(
                tap({
                    next: () => {
                        this._updateCurrentUser();
                        this._router.navigateByUrl('/login').then();
                    },
                    error: () => {
                        this._updateCurrentUser();
                        this._router.navigateByUrl('/login').then();
                    },
                }),
            );
    }

    /**
     * Verify and cache the currently logged in user
     *
     * @returns User
     */
    public setCurrentUser$(): Observable<User> {
        return this._authApi.getlogin$(this.xAccessToken ?? '')
            .pipe(
                tap({
                    next: user => {
                        this._updateCurrentUser(user);
                    },
                    error: () => {
                        this._updateCurrentUser();
                    },
                }),
            );
    }

    private _updateCurrentUser(user?: User): void {
        if (user && this.xAccessToken) {
            this.user$.next(user);
            this.isLoggedIn$.next(true);
            localStorage.setItem('bukiro-wishlist-token', this.xAccessToken);
        } else {
            this.xAccessToken = null;
            this.user$.next(null);
            this.isLoggedIn$.next(false);
            localStorage.removeItem('bukiro-wishlist-token');
        }
    }

}
