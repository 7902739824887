<div
    class="backdrop"
    (click)="closeMenu.emit()"
></div>

<mat-card
    class="content"
    appearance="raised"
>

    <mat-selection-list
        class="user-list"
        [multiple]="false"
    >
        <div
            *ngIf="user && !isAdmin"
            class="user-list__option"
        >
            <mat-list-option
                [value]="user"
                (click)="openList.emit(user)"
            >
                Meine Wunschliste
            </mat-list-option>
            <button
                mat-mini-fab
                title="Benutzereinstellungen bearbeiten"
                type="button"
                color="primary"
                (click)="configUser.emit(user)"
            >
                <mat-icon>settings</mat-icon>
            </button>
        </div>

        <h3>Wunschlisten</h3>

        <ng-container *ngIf="users">
            <div
                *ngFor="let otherUser of users; trackBy: trackById"
                @fadeAnimation
                class="user-list__option"
            >
                <mat-list-option
                    [value]="otherUser"
                    (click)="openList.emit(otherUser)"
                >
                    {{ otherUser.name }}
                </mat-list-option>
                <button
                    *ngIf="isAdmin"
                    mat-mini-fab
                    title="Benutzereinstellungen bearbeiten"
                    type="button"
                    color="none"
                    (click)="configUser.emit(otherUser)"
                >
                    <mat-icon>settings</mat-icon>
                </button>
                <button
                    *ngIf="isAdmin"
                    mat-mini-fab
                    title="Benutzer löschen"
                    type="button"
                    color="none"
                    (click)="deleteUser.emit(otherUser)"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </ng-container>

    </mat-selection-list>

    <button
        class="content__item"
        *ngIf="isAdmin"
        title="Neuen Benutzer anlegen"
        type="button"
        mat-raised-button
        color="none"
        (click)="createUser.emit()"
    >
        <mat-icon>person_add</mat-icon>
        Benutzer hinzufügen
    </button>
    <button
        class="content__item"
        *ngIf="user && isAdmin"
        title="Admin konfigurieren"
        type="button"
        mat-raised-button
        color="none"
        (click)="configUser.emit(user)"
    >
        <mat-icon>settings</mat-icon>
        Admin konfigurieren
    </button>
    <button
        class="content__item"
        *ngIf="user"
        title="Abmelden"
        type="button"
        mat-raised-button
        color="primary"
        (click)="logout.emit()"
    >
        <mat-icon>logout</mat-icon>
        {{ user.name }} abmelden
    </button>
</mat-card>
