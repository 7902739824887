import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, switchMap, take, tap } from 'rxjs';
import { Defaults } from 'src/app/shared/definitions/data/defaults';
import { User } from 'src/app/shared/definitions/models/user';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { UsersService } from 'src/app/shared/services/users/users.service';
import { CrossFieldErrorMatcher } from '../../definitions/models/cross-field-error-matcher';

const minPasswordLength = 8;

@Component({
    selector: 'app-first-steps',
    templateUrl: './first-steps.component.html',
    styleUrls: ['./first-steps.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstStepsComponent {

    public readonly passwordForm: FormGroup;

    public readonly isCreating$ = new BehaviorSubject(false);

    public readonly crossFieldErrorStateMatcher = new CrossFieldErrorMatcher();

    constructor(
        private readonly _usersService: UsersService,
        private readonly _matSnackBar: MatSnackBar,
        private readonly _router: Router,
        private readonly _authService: AuthService,
    ) {
        if (this._authService.isLoggedIn$.getValue()) {
            this._router.navigateByUrl('/wishlist').then();
        }

        this._usersService.users$()
            .pipe(
                take(1),
                tap({
                    next: users => {
                        if (users.length) {
                            this._router.navigateByUrl('/wishlist').then();
                        }
                    },
                    error: () => {
                        this._matSnackBar.open(
                            'Die Datenbankverbindung ist fehlgeschlagen.',
                            '',
                            { duration: Defaults.SnackBarErrorDuration },
                        );
                    },
                }),
            )
            .subscribe();

        this.passwordForm = new FormGroup(
            {
                newPassword: new FormControl(
                    '',
                    [Validators.required, Validators.minLength(minPasswordLength)],
                ),
                newPasswordRepeat: new FormControl(
                    '',
                ),
            },
            {
                validators: [this._validatePasswordRepeat.bind(this)],
            },
        );
    }

    public createAdmin(): void {
        this.isCreating$.next(true);

        const adminUser = new User('admin');

        this._usersService.saveUser$(adminUser, this.passwordForm.controls['newPassword'].value).
            pipe(
                tap({
                    next: () => this._matSnackBar.open(
                        'Der Admin-User wurde erstellt. Du wirst jetzt angemeldet...',
                        '',
                        { duration: Defaults.SnackBarInfoDuration },
                    ),
                    error: () => this._matSnackBar.open(
                        'Der Admin-User konnte nicht erstellt werden; Es ist ein Fehler aufgetreten.',
                        '',
                        { duration: Defaults.SnackBarErrorDuration },
                    ),
                }),
                switchMap(() => this._authService.login$(adminUser.id, this.passwordForm.controls['newPassword'].value)),
                tap({
                    next: () => {
                        this._router.navigateByUrl('/wishlist').then();
                    },
                    error: () => {
                        this._matSnackBar.open(
                            'Die Anmeldung ist fehlgeschlagen',
                            '',
                            { duration: Defaults.SnackBarErrorDuration },
                        );

                        this._router.navigateByUrl('/login').then();
                    },
                }),
            )
            .subscribe({
                error: () => this.isCreating$.next(false),
                complete: () => this.isCreating$.next(false),
            });
    }

    private _validatePasswordRepeat(control: AbstractControl): ValidationErrors | null {
        const newPassword = control.value.newPassword;
        const newPasswordRepeat = control.value.newPasswordRepeat;

        if (newPasswordRepeat !== newPassword) {
            return { matchpassword: true };
        }

        return null;
    }

}
