<app-title-bar [(menuOut)]="menuOut"></app-title-bar>
<div class="content">
    <app-user-menu *ngIf="users$ | async as users"
        [ngClass]="{ 'out': menuOut }"
        [users]="users"
        [user]="user$ | async"
        (logout)="logout()"
        (createUser)="openNewUserDialog()"
        (configUser)="openConfigUserDialog($event, users)"
        (deleteUser)="openDeleteUserDialog($event)"
        (openList)="openList($event)"
        (closeMenu)="menuOut = false"
    ></app-user-menu>
    <router-outlet></router-outlet>
</div>
