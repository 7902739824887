<ng-container *ngIf="wish">

    <div
        *ngIf="isGift; else CheckboxesTemplate"
        class="gift-icon"
    >
        <mat-icon>redeem</mat-icon>
    </div>

    <ng-template #CheckboxesTemplate>
        <mat-checkbox
            *ngIf="canSeeTakers && !(currentUser?.isAdmin) && (!otherTakers.length || wish.allowMultipleTakers)"
            class="checkbox-take-wish"
            [checked]="isTaker"
            color="primary"
            (change)="takeWish($event)"
        ></mat-checkbox>
        <mat-checkbox
            *ngIf="canSeeTakers && otherTakers.length && ((currentUser?.isAdmin) || (!isTaker && !wish.allowMultipleTakers))"
            class="checkbox-take-wish"
            checked
            disabled
        ></mat-checkbox>
    </ng-template>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon
                *ngIf="!isGift && canSeeTakers && otherTakers.length"
                class="icon-wish-taken"
                matTooltip="Der Wunsch ist von {{ otherTakers.length }} Anderen markiert"
                color="primary"
            >
                person_add
            </mat-icon>

            <h4 class="wish-title">
                {{ isGift ? 'Geschenk von ' + giftingUser + ':' : '' }}
                {{ wish.title }}
            </h4>
        </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="detail">
            <div class="detail__content">
                <span *ngFor="let url of urls; let index = index; let first = first; trackBy: trackByIndex">
                    <strong [class.invisible]="!first">{{ (urls?.length ?? 0) > 1 ? 'Links: ' : 'Link: ' }}</strong>
                    <a [href]="url" target="_blank">{{ wish.links[index] }}</a>
                </span>

                <ng-container *ngIf="wish.details; else NoDetailsTemplate">
                    <strong>Details: </strong>
                    <p [innerText]="wish.details"></p>
                </ng-container>

                <ng-template #NoDetailsTemplate>
                    <p>
                        Es sind keine Details zu dem {{ isGift ? 'Geschenk' : 'Wunsch' }} angegeben.
                    </p>
                </ng-template>

                <mat-checkbox
                    *ngIf="wish.allowMultipleTakers"
                    checked
                    disabled
                >
                    Dieser Wunsch kann von mehreren Personen markiert werden.
                </mat-checkbox>
            </div>

            <ng-container *ngIf="!isGift && canSeeTakers && otherTakers.length">
                <div

                    class="detail__takers-title"
                >
                    <mat-icon color="primary">person_add</mat-icon>

                    <strong>
                        {{ isTaker ? 'Auch markiert von: ' : 'Markiert von: ' }}
                    </strong>
                </div>

                <div
                    class="detail__takers"
                >
                    <mat-checkbox
                        *ngFor="let taker of otherTakers; trackBy: trackByIndex"
                        checked
                        disabled
                    >
                        {{ taker }}
                    </mat-checkbox>
                </div>
            </ng-container>
        </div>

    </mat-expansion-panel>

</ng-container>
