import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Md5 } from 'ts-md5';
import { LoginToken } from '../definitions/interfaces/login-token';
import { User } from '../definitions/models/user';
import { apiUrl } from '../util/api-url';

@Injectable({
    providedIn: 'root',
})
export class AuthApiService {

    constructor(
        private readonly _http: HttpClient,
    ) { }

    public getlogin$(token: string): Observable<User> {
        return this._http.get<User>(
            `${ apiUrl }/getLogin`,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { headers: new HttpHeaders({ 'x-access-Token': token }) },
        )
            .pipe(
                map(user =>
                    Object.assign(new User(user.name ?? ''), user),
                ),
            );
    }

    public login$(userId: string, password: string): Observable<LoginToken> {
        return this._http.post<LoginToken>(
            `${ apiUrl }/tryLogin`,
            { userId, password: Md5.hashStr(password) },
        );
    }

    public logout$(token: string): Observable<HttpResponse<null>> {
        return this._http.post<HttpResponse<null>>(
            `${ apiUrl }/logout`,
            { token },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { headers: new HttpHeaders({ 'x-access-Token': token }) },
        );
    }

}
