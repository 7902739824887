<mat-card appearance="raised">
    <button
        title="Menü"
        type="button"
        class="menu-button maxwidth-s"
        mat-fab
        color="primary"
        (click)="menuOut = !menuOut"
    >
        <mat-icon *ngIf="!menuOut">menu</mat-icon>
        <mat-icon *ngIf="menuOut">menu_open</mat-icon>
    </button>

    <h1 class="title">
        Wunschlixt
    </h1>

    <div class="corner-ribbon">
        <div class="corner-ribbon__frame">
            <div class="corner-ribbon__ribbon"></div>
        </div>
    </div>
</mat-card>
