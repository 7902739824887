<h2 mat-dialog-title>
    Neuen Benutzer anlegen
</h2>

<form
    [formGroup]="newUserForm"
    mat-dialog-content
    (ngSubmit)="createUser()"
>

    <div>
        <mat-form-field>
            <mat-label>Name des Benutzers</mat-label>
            <input
                formControlName="username"
                matInput
                maxlength="30"
                title="Name des Benutzers"
                type="text"
            >
            <mat-error *ngIf="newUserForm.controls['username'].touched && newUserForm.controls['username'].errors?.['required']">
                Das Feld muss ausgefüllt sein
            </mat-error>
        </mat-form-field>
    </div>

</form>

<div
    mat-dialog-actions
    align="end"
>
    <button
        title="Abbrechen"
        type="button"
        mat-raised-button
        mat-dialog-close
    >
        Abbrechen
    </button>

    <button
        title="Anlegen"
        type="button"
        mat-raised-button
        mat-dialog-close
        color="primary"
        [disabled]="newUserForm.invalid"
        (click)="createUser()"
    >
        Anlegen
    </button>
</div>
